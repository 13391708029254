<template>
  <section id="account">
    <div class="page">
      <form ref="user" @submit.prevent="updateUser" class="card">
        <div class="grid-x row grid-margin-x">
          <div class="cell auto">
            <app-label required>Firstname</app-label>
            <app-input v-model="user.firstname" required :tabindex="1" />
          </div>

          <div class="cell auto">
            <app-label required>Address</app-label>
            <app-input v-model="user.address.line1" required :tabindex="4" />
          </div>
        </div>

        <div class="grid-x row grid-margin-x">
          <div class="cell auto">
            <app-label required>Last name</app-label>
            <app-input v-model="user.lastname" required :tabindex="2" />
          </div>

          <div class="cell auto grid-x">
            <div class="cell small-5 postal-code">
              <app-label required>ZIP Code</app-label>
              <app-input
                autocomplete="postalCode"
                v-model="user.address.postalCode"
                required
                :tabindex="5"
              />
            </div>

            <div class="cell auto">
              <app-label required>City</app-label>
              <app-input v-model="user.address.city" required :tabindex="6" />
            </div>
          </div>
        </div>

        <div class="grid-x row grid-margin-x">
          <div class="cell auto">
            <app-label>Company</app-label>
            <app-input v-model="user.company" :tabindex="3" />
          </div>

          <div class="cell auto">
            <app-label v-if="countries && countries.length">Country</app-label>
            <app-select v-if="countries && countries.length" :options="countries" v-model="user.address.country" tabindex="7" />
          </div>
        </div>

        <div class="grid-x">
          <div class="cell auto" />
          <div class="cell shrink">
            <app-button type="submit" size="large" :loading="isLoadingToSaveUser" tabindex="8">Save changes</app-button>
          </div>
        </div>
      </form>
      <form v-if="!user.googleId" ref="password" class="card" @submit.prevent="updatePassword">
        <div class="grid-x row grid-margin-x">
          <div class="cell auto">
            <app-label required>Old password</app-label>
            <app-input v-model="oldPassword" type="password" required />
          </div>
          <div class="cell auto">
            <app-label required>New password</app-label>
            <app-input v-model="newPassword" type="password" required />
          </div>
        </div>

        <div class="grid-x grid-margin-x">
          <div class="cell auto">
          </div>
          <div class="cell shrink">
            <app-button type="submit" size="large" :loading="isLoadingToSavePassword">Save changes</app-button>
          </div>
        </div>
      </form>

      <form class="card" @submit.prevent="openConfirmationModal">
        <div class="grid-x grid-margin-x delete-account">
          <div class="cell auto">
            <p>Deleting your account will suspend your account immediately. All API calls will fail upon cancellation.</p>
            <p>Please be sure this is your desired action.</p>
          </div>

          <div class="cell shrink">
            <app-button type="submit" size="large" look="warning">Delete account</app-button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import countriesData from '@/services/data/countries';
import userApi from '@/services/api/user';
import auth from '@/services/auth';
import utils from '@/services/utils/utils';

export default {
  name: 'account',

  data() {
    return {
      user: {
        firstname: '',
        lastname: '',
        company: '',
        address: {
          line1: null,
          city: null,
          postalCode: null,
          state: null,
          country: null,
        },
      },
      oldPassword: null,
      newPassword: null,
      countriesData,
      countries: null,
      isLoadingToSaveUser: false,
      isLoadingToSavePassword: false,
    };
  },

  async mounted() {
    this.countries = utils.enumToOptions(this.countriesData);

    try {
      const user = await userApi.getMe();

      if (!user.address) {
        user.address = {
          line1: null,
          city: null,
          postalCode: null,
          state: null,
          country: null,
        };
      }
      this.user = user;
    } catch (er) {
      this.$message.show({
        title: 'Error',
        text: 'Impossible to get your informations',
        confirmText: 'Ok',
        hasCancel: false,
      });
    }
  },

  methods: {
    async updateUser() {
      if (this.$refs.user.checkValidity()) {
        this.isLoadingToSaveUser = true;

        const {
          userId,
          email,
          apiKey,
          createdAt,
          deletedAt,
          googleId,
          isActive,
          isInDefaultPayment,
          lastLoginAt,
          secretSignature,
          stripeCustomerId,
          subscription,
          updatedAt,
          ...user
        } = this.user;

        try {
          user.address.state = 'unavailable';

          await userApi.update(user);

          this.$notification.show({
            text: 'User update successful !',
          });
        } catch (error) {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to update your informations',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        } finally {
          this.isLoadingToSaveUser = false;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },

    async updatePassword() {
      if (this.$refs.password.checkValidity()) {
        this.isLoadingToSavePassword = true;

        try {
          await userApi.updatePassword(this.oldPassword, this.newPassword);

          this.$notification.show({
            text: 'Password update successful !',
          });
        } catch (error) {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to update your informations, please check your old password',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        } finally {
          this.isLoadingToSavePassword = false;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },

    openConfirmationModal() {
      this.$message.show({
        title: 'Delete account',
        text: 'Are you sure you want to deactivate your account?\nThis action cannot be undone. Please downgrade to the Free Plan first.',
        confirmText: 'Delete account',
        cancelText: 'Cancel',
        confirmLook: 'warning',
        onConfirm: () => this.deleteAccount(),
        hasCancel: true,
      });
    },

    async deleteAccount() {
      try {
        await userApi.delete();
        await auth.logout();

        this.$router.push({ name: 'login' });

        this.$notification.show({
          text: 'Account deleted successfully !',
        });
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to delete your account',
          confirmText: 'Ok',
          hasCancel: false,
        });

        throw error;
      }
    },
  },
};
</script>
<style lang="sass">
#account
  .page
    @include page

    .delete-account
      align-items: center

    h1
      color: $white
      margin-bottom: 30px

      svg
        margin-right: 10px

    p
      margin-top: 30px

    .postal-code
      margin-right: 20px

    .row
      margin-bottom: 20px

    .card
      background-color: $primary90
      border-radius: 8px
      padding: 40px
      margin-bottom: 40px

      p
        margin: 0
</style>
